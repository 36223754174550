/* This is the CSS file used for this project */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.form-control{
  display:inline-block;
  width:100%
}

.without_ampm::-webkit-datetime-edit-ampm-field {
   display: none;
 }

 #salon-section {
  scroll-behavior: smooth;
 }
